import React, { useState, useRef, useEffect } from 'react'
import Web3 from 'web3';
import Deposit from '../../components/deposit';
import Withdraw from '../../components/withdraw';
import Header from '../../components/header';
import Footer from '../../components/footer';
import contracts from '../../gateway';
import { ethers } from "ethers";

import { useWeb3React } from "@web3-react/core"
import { connectors } from "../../supportedIds"
const web3 = new Web3(Web3.givenProvider);

const Home = () => {

    //Web3 Stuff
    const { active, account, activate} = useWeb3React()

    function networkCheck(id){
        //Checks if the current chain is supported (5 being goerli testnet as an exception)
        if(id === 1)
        {
          setIsNetwork(true);
          
        }
        else
        {
          setIsNetwork(false);
        }
        setIsLoadingNetwork(false);
      }
    
      useEffect(() => {
        //Checks if the network was switched
        const id = setInterval(() => {
          if (web3.givenProvider !== null) 
          {
            
            setIsMetamask(true);
            web3.eth.net.getId()
            .then(function (result){
              networkCheck(result);  
            
            
          })
          }
          else
          {
            setIsMetamask(false);
          } // <-- (3) invoke in interval callback
          }, 5000);
          // <-- (2) invoke on mount
      
        return () => clearInterval(id);
      }, [])
    
      useEffect(() => {
        connectWallet();
      },[active])

    const switchNetwork = async () => {
        window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{
             chainId: "0x1"
          }]
       })
        .catch((error) => {
        console.log(error)
        }) 
    }
      
     
    
      /*async function disconnect() {
        try {
          deactivate()
        } catch (ex) {
        }
      }*/
    
    
      
    //Metamask states
    const [isNetwork, setIsNetwork] = useState(false);
    const [isLoadingNetwork, setIsLoadingNetwork] = useState(true);
    const [isMetamask, setIsMetamask] = useState(true);

    useEffect(() => {
        if (web3.givenProvider !== null) 
        {
    
          web3.eth.net.getId()
          .then(function (result){
            networkCheck(result);  
        })
        }
        else
        {
          setIsMetamask(false);
        }
        
        
        
      }, []);
    
    const [tab, setTab] = useState(0);
    const [OxOFactory, setOxOFactory] = useState(null);

    const [mintToken, setMintToken] = useState(null);
    const [depositTokens, setDepositTokens] = useState([]);
    const [busy, setBusy] = useState(false);

    const [msg, setMsg] = useState({
        text: "Connect Wallet",
        color: "54, 210, 205"
    });

    const tokenRef = useRef();
/*
    const mint = async () => {
        if(OxOFactory !== null) {
            setBusy(true);
            try {
                const Token = new ethers.Contract(
                    mintToken.address,
                    contracts.MockToken.abi,
                    OxOFactory.signer
                );
                
                const amount = 10;
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                const account = accounts[0];
                
                if(account !== undefined) {
                    const gasEstimate = await Token.estimateGas.mint(account, amount);
                    let tx = await Token.mint(
                        account, 
                        ethers.utils.parseUnits(amount.toString(), 18).toHexString(),
                        { gasLimit: (parseInt(gasEstimate) + 1000).toString() }
                    );
                    
                    let receipt = await tx.wait();
                    if(receipt.status === 1) {
                        setMsg({
                            text: `Tokens Minted to ${account}`,
                            color: "lightgreen"
                        });
                    }else{
                        setMsg({
                            text: "Error Minting Token",
                            color: "pink"
                        });
                    }
                    
                }else{
                    setMsg({
                        text: "Connect Wallet",
                        color: "bisque"
                    });
                }
            } catch (error) {
                setMsg({
                    text: "Error Minting Token",
                    color: "pink"
                });
            }
            setBusy(false);
        

    };}*/
    const SupportedChainId = 1;
    
    const [user, setUser] = useState({
        address: "",
        balance: 0
    });

    const connectWallet = async () => {
        if (window.ethereum && active) {
            try {
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                const account = accounts[0];
                const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
                const signer = provider.getSigner();
                const balance = await provider.getBalance(account); 
                const { chainId } = await provider.getNetwork();

                setUser({
                    address: account,
                    balance: ethers.utils.formatEther(balance)
                });
                
                if (chainId !== SupportedChainId) {
                    setMsg({
                        text: "Please connect to the Ethereum Mainnet and refresh page",
                        color: "pink"
                    });
                    return;
                }
                setOxOFactory(new ethers.Contract(contracts.OxOFactory.address, contracts.OxOFactory.abi, signer));

                setMsg({text: "", color: "bisque"});
            } catch (error) {
                console.log(error);
                setMsg({text: "Error connecting wallet", color: "pink"});
            }
        }
    }

    return (
        <>  <div className="background-body" 
                style={{backgroundImage: `url(/bg.png)`,
                        backgroundPosition: 'center',
                        backgroundSize: '100% contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundAttachment: 'fixed'}}>
            <dialog className="dialog-box" id="deposit-dialog">
                <form method="dialog">
                    <h2 className="title center">Transaction completed!</h2>
                    <p>Keep the token below to be able to withdraw your assets in the future.</p>
                    
                    

                    <div style={{backgroundColor:"#212529", marginTop: "30px"}} className="nes-field">
                        <input type="text" id="dark_field" ref={tokenRef} className="tx-complete-input" readonly='readonly'/>
                    </div>
                    <div className="copy-clipboard-btn is-success" style={{marginTop: "5px"}} onClick={()=>{navigator.clipboard.writeText(tokenRef.current.value);}}>Copy to Clipboard</div>
                    <p style={{color:"#ff0000"}}>If you lose this token you will not be able to recover your assets!</p>
                    <menu className="dialog-menu" style={{padding: "0px"}}>
                        <button onClick={()=>{setDepositTokens(depositTokens => [...depositTokens, tokenRef.current.value])}}className="tx-complete-btn is-success" style={{marginTop: "25px"}}>I have kept the token</button>
                    </menu>
                </form>
            </dialog>
            <div id="overlay"></div>

            <Header setOxOFactory={ setOxOFactory } setMsg={ setMsg } msg={ msg } account={ account }/>
            <h1 className="center" style={{paddingTop:"48px",fontFamily:"saira",fontWeight:"Bold"}}>Privacy Mixer & Flashloan Protocol</h1>
          <div className="space-border"></div>
          <p className="center" style={{width:"50%",margin:"auto",color:"rgba(170,170,170,255)"}}>A non-traceable privacy ERC-20 transactions mixer is a decentralized platform that provides secure and anonymous transaction mixing. It uses smart contract technology to preserve the privacy of the source and destination of transactions, making them untraceable. <br/><br/>Additionally, it utilizes a flash-loan feature to generate fees that are distributed to $0x0 token holders. Ideal for users who place a high value on privacy and security.</p>
          
            {isNetwork ? 
          <>
          {active ? 
          <>
            <div className="body-content swap">
                <div>
                    <button className={["nes-btn", (tab === 0? "current": "")].join(" ")} onClick={() => {setTab(0)}}>
                        Deposit
                    </button>
                    <button className={["nes-btn", (tab === 1? "current": "")].join(" ")} onClick={() => {setTab(1)}}>
                        Withdraw
                    </button>
                </div>
                <div className="dapp-box">
                    {tab === 0 ? <Deposit OxOFactory={ OxOFactory } setMsg={ setMsg } setMintToken={setMintToken} tokenRef={tokenRef}/> :
                                 <Withdraw OxOFactory={ OxOFactory } setMsg={ setMsg }/>}
                </div>
                
            </div>
            {(depositTokens.length > 0) ?
            <>
              <div className='token-content'>
                <h2 className='center'>Tokens generated this session:</h2>
                <p style={{color:"#ff0000"}} className="center">Refreshing this window will make these tokens disappear!</p>
                {depositTokens?.map((d,i) => 
                                (
                                  <div style={{borderTop:"1px solid rgba(255,255,255,0.3)",borderBottom:"1px solid rgba(255,255,255,0.3)"}}>
                                    {/*@ts-ignore*/}
                                    <h3 className='center'>-- {i+1} --</h3>
                                        <input type="text" value={d} className="tx-complete-input" readonly='readonly'/>
                                        <div className="copy-clipboard-btn is-success" style={{marginTop: "20px",marginBottom:"20px"}} onClick={()=>{navigator.clipboard.writeText(d);}}>Copy to Clipboard</div>
                                  </div>
                                ))}
                </div>
            </>
            :
            <></>
            }
            
          
          </>
          :
        
          <>
                        
                        <div className="body-content">
                            <div className="connect-box">
                              <button className="connect-button" onClick={()=>{activate(connectors.injected)}}><img src="/images/mm.svg" alt="metam"  style={{width:"24px",height:"24px"}}/> Metamask</button>
                              <button className="connect-button" onClick={()=>{activate(connectors.injected)}}><img src="./images/tw.svg" alt="tw"  style={{width:"24px",height:"24px"}}/> TrustWallet</button>
                              <button className="connect-button" onClick={()=>{activate(connectors.walletConnect)}}><img src="/images/wc.svg" alt="wc"  style={{width:"24px",height:"24px"}}/> WalletConnect</button>
                              <button className="connect-button" onClick={()=>{activate(connectors.coinbaseWallet)}}><img src="/images/cb.svg" alt="cbw"  style={{width:"24px",height:"24px"}}/> Coinbase</button>
                            </div>
                        </div>
          </>
          }
        
        
        </>
                  
        :
        <>
        <div className="body-content">
        <div className="connect-box">
          {!isMetamask ?
            <p>Please install the Metamask Browser Extension or use the Metamask Mobile App.</p>
          :
          <>
          {isLoadingNetwork ?
            <p>Checking Network.</p>
          :
            <>
                        <p>
                          Wrong Network! Please switch over to the Ethereum Mainnet.
                        </p>
                        <button className="connect-button" onClick={switchNetwork}> Switch Network</button>
            </>
          } 
          </>
        }
        </div>
        </div>
        </>
        }
            
            {/*<div style={{margin: "40px 0"}}>
                    {
                    mintToken !== null ? (
                        <button 
                            style={{ width: "300px", margin: "10px auto", display: "block" }}
                            type="button" className={["nes-btn is-success", busy? "is-disabled" : ""].join(" ")} onClick={mint} >
                                {busy ? (
                                    'Minting...'
                                ) : (
                                    `Mint ${mintToken.symbol} Tokens`
                                )}
                        </button>
                    ) : (
                        <></>
                    )}
            </div>
            <button type="button" className="nes-btn github-btn active">
                <i className="nes-icon github"></i>
                    </button>*/}
            
            <Footer />
            </div>
        </>
    )
}

export default Home;