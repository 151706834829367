import { ethers } from "ethers";
import OxOFactory from "./abi/OxOFactory.json";
import OxOPool from "./abi/OxOPool.json";
import ERC20 from "./abi/ERC20.json";
import MockToken from "./abi/MockToken.json";

const contracts = {
    "OxOFactory": {
        "address": "0xd04780e6B0C40024440596CaeEd7d7d9E3eB409B",
        "abi": OxOFactory['abi']
    },
    "OxOPool": {
        "abi": OxOPool['abi']
    },
    "ERC20": {
        "abi": ERC20['abi']
    },
    "MockToken": {
        "abi": MockToken['abi']
    }
}

export default contracts;
