import React from 'react';

const footer = () => {
    return(

        <div style={{borderTop:"1px solid rgba(255,255,255,0.1",backgroundColor:"#15171a"}}> 

        
           <div class="container-footer">
                    <div class="footer-left">
                            <span>&copy; Copyright 2023 0x0.ai.</span>
                    </div>
      
                        <div class="social-icons">
                            <a href="https://t.me/Portal0x0" target="_blank"><img width="18px" src={'/images/tg.svg'} /></a>
                            <a href="https://twitter.com/0x0audits" target="_blank"><img width="18px" src={'/images/twt.svg'} /></a>
                            <a href="https://medium.com/@privacy0x0" target="_blank"><img width="18px" src={'/images/med.svg'} /></a>
                        </div>
            </div>
        </div>
    );
}

export default footer;