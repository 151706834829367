import React, { useState, useRef ,useEffect} from 'react';
import contracts from '../gateway';
import { ethers } from "ethers";
import { serialize, h1, bn128 } from '../lib/AltBn128';
import crypto from 'crypto';

const Deposit = ({ OxOFactory, setMsg, tokenRef }) => {

    const [tokens, setTokens] = useState(null);
    const [tokensList, setTokensList] = useState(null);
    const [amounts, setAmounts] = useState([0.1,1,10,100]);
    const [amount, setAmount] = useState(1);
    const [token, setToken] = useState(null);
    const [OxOToken, setOxOToken] = useState(null);
    const [busy, setBusy] = useState(false);
    const [approved, setApproved] = useState(false);
    const [lastAmnt, setLastAmnt] = useState(false);
    const [tokenDecimals, setTokenDecimals] = useState(0)

    async function getTokens(){
        if(OxOFactory !== null && tokens === null) {
            let tokensAux = [];
            //let tokenAux = []
            await OxOFactory.allPoolsLength().then(async (length) => {
                length = length.toNumber();
                for(var i=0; i<length; i++) {
                    await OxOFactory.allPools(i).then(async (pool) => {
                        var pc = new ethers.Contract(
                            pool,
                            contracts.OxOPool.abi,
                            OxOFactory.signer
                        )
    
                        await pc.token().then(async (tok) => {
                            var tc = new ethers.Contract(
                                tok,
                                contracts.ERC20.abi,
                                OxOFactory.signer
                            );
                            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                            const account = accounts[0];
                            if(account !== undefined) {
                                try {
                                    const b = await tc.balanceOf(account);
                                    const decimalsAux = await tc.decimals();
                                    //setTokenDecimals(decimals);
                                    const balanceAux = ethers.utils.formatUnits(b, decimalsAux);
                                    const symbol = await tc.symbol()
                                    var d = {symbol: symbol, address: tok, pool: pool, i:i, balance: balanceAux, decimals: decimalsAux};
                                    tokensAux.push(d);
                                } catch (error) {
                                    return 0;
                                }
                            }
                        });
    
                    });
                }
                //console.log(tokensAux);
                setTokens(tokensAux);
                setTokensList(tokensAux);
                setToken(tokensAux[0]);
                setTokenDecimals(tokensAux[0].decimals)
                //setTokens(tokensAux);
                //setToken();
            })
        }
    }

    getTokens();

    const refreshBalance = async (tok) => {
        if(tok !== null) {
            var tc = new ethers.Contract(
                tok.address,
                contracts.ERC20.abi,
                OxOFactory.signer
            );
            
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const account = accounts[0];

            if(account !== undefined) {
                try {
                    let tokensAux = tokens;
                    const b = await tc.balanceOf(account);
                    const balanceAux = ethers.utils.formatUnits(b, tok.decimals);
                    var tokenAux = {symbol: tok.symbol, address: tok.address, pool: tok.pool, i:tok.i, balance: balanceAux, decimals: tok.decimals};
                    tokensAux.splice(tok.i,1,tokenAux);
                    setTokens(tokensAux);
                    setToken(tokenAux);
                } catch (error) {
                    return 0;
                }
            }
        }

        return 0;
    }
    

    const changeToken = (event) => {
        try{
            //console.log(event);
            //console.log(tokSelected.current);
            setToken(tokens[event]);
            setTokenDecimals(tokens[event].decimals);
            setApproved(false);
            setLastAmnt(0);
            /*var t = tokSelected.current.value;
            for(var i=0; i<tokens.length; i++) {
                    if(tokens[i].pool === t) {
                        refreshBalance(tokens[i]).then((b) => {
                            setToken({...tokens[i], balance: b});
                        });
                        
                        break;
                    }
                }*/
        }
       catch(ex){
        console.log(ex);
       }
        
    }

    const approve = async () =>{
        if(token) {
            
            var Token = new ethers.Contract(
                token.address,
                contracts.ERC20.abi,
                OxOFactory.signer
            );
            
            if(token.balance < amount)
            {
                setMsg({text: "Not enough balance", color: "255,192,203"})
                return;
            }
            const amountToken = ethers.utils.parseUnits(amount.toString(), tokenDecimals).toHexString();
            if(!busy)
            {
                try{
                    let gasEstimate = await Token.estimateGas.approve(token.pool, amountToken);
                    setBusy(true);
                    await Token.approve(token.pool, amountToken, {gasLimit: gasEstimate});
                    setBusy(false);
                    setApproved(true);
                    setLastAmnt(amount);
                }
                catch(ex)
                {
                    setBusy(false);
                    console.log(ex);
                }
            }
            
        }
    }

    const deposit = async () => {
        if(token) {
            setMsg({});
            
            var Pool = new ethers.Contract(
                token.pool,
                contracts.OxOPool.abi,
                OxOFactory.signer
            );
            
            if(token.balance < amount)
            {
                setMsg({text: "Not enough balance.", color: "255,192,203"})
                return;
            }

            const amountToken = ethers.utils.parseUnits(amount.toString(), tokenDecimals).toHexString();
            
            
                
            const targetAddress = '';
            const randomSecretKey = crypto.randomBytes(32).toString('hex')
            const stealthSecretKey = h1(
                serialize([randomSecretKey, targetAddress])
            )
            
            const stealthPublicKey = bn128.ecMulG(stealthSecretKey).map(x => '0x' + x.toString(16))
            
            const ringIndex = await Pool.getCurrentRingIndex(amountToken);
            const OxOToken = {
                randomSecretKey: randomSecretKey,
                amount: amountToken,
                ringIndex: ringIndex.toHexString()
            };

            if(!busy)
            {
                try {
                    setBusy(true);
                    let gasEstimate = undefined;
                    try{
                        gasEstimate = await Pool.estimateGas.deposit(
                            amountToken,
                            stealthPublicKey
                        )
                    }
                    catch(ex)
                    {
                        setMsg({text: "Still Approving.", color: "255,192,203"})
                        setBusy(false);
                        return;
                        
                    }
                    
                    let tx = await Pool.deposit(
                        amountToken,
                        stealthPublicKey,
                        {gasLimit: gasEstimate}
                    );
                    
                    const receipt = await tx.wait();
                    if(receipt.status === 1) {
                        tokenRef.current.value = `0x0.ai:${OxOToken.amount}:${OxOToken.ringIndex}:${token.pool}:${OxOToken.randomSecretKey}`;
                        document.getElementById("deposit-dialog").setAttribute("open", "");
                        setMsg({text: "Deposit successful", color: "54, 210, 205"});
                        refreshBalance(token);
    
                    }else{
                        setMsg({text: "Deposit failed", color: "255,192,203"});
                    }
                    setBusy(false);
                    setApproved(false);
                    setLastAmnt(0);
                } catch (error) {
                    console.log(error.message);
                    setMsg({text: "Deposit failed", color: "255,192,203"});
                    setBusy(false);
                }
            }
            
            
        }
    }

    const changeAmount = (cAmount) =>{
        setAmount(cAmount);
        if(lastAmnt >= cAmount)
        {
            setApproved(true);
        }
        else
        {
            setApproved(false);
        }
    }
    

      useEffect(() => {
        try{
            if(token.symbol == '0x0')
            {
                setAmounts([1000,10000,100000,1000000]);
                setAmount(1000);
                setMsg({text: "Deposits temporarily unavailable for 0x0 token", color: "255,192,203"});
            }
            if(token.symbol == 'WETH')
            {
                setAmounts([0.1,1,10,100]);
                setAmount(0.1);
                setMsg({});
            }
            if(token.symbol == 'DAI')
            {
                setAmounts([100,1000,10000,100000]);
                setAmount(100);
                setMsg({});
            }

        }catch(ex){

        }
        
      },[token])
    
    return (
        <>
            <h2 htmlFor="" className='center'>Disabled!</h2>
            <p>As per the official announcement on Twitter, the deposits are no longer being accepted on Mixer v1, as they have been permanently disabled, source: <a href="https://twitter.com/0x0Audits/status/1640779096207786019" target="_blank" rel="noopener noreferrer">https://twitter.com/0x0Audits/status/1640779096207786019</a>. To initiate new deposits, please utilize Mixer v2, which is available at the following link: <a href="https://mixer.0x0.ai" target="_blank" rel="noopener noreferrer">https://mixer.0x0.ai</a>.</p>
        </>
    )
}

export default Deposit;