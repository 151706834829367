import React, { useState } from 'react';
import { ethers } from "ethers";
import contracts from '../gateway';
import Truncate from 'react-truncate';

const Header = ({ setOxOFactory, setMsg, msg,account }) => {
    
    
    
    return (
        <>
            <div class="container-header">
            <div class="container-hf">

                            <div class="justify-left">
  
                                        <a href="https://0x0.ai">
                                            <img alt="Home" class="logo" src="/images/logo.png" />
                                        </a>
                                        
                            </div>
                            
                            {(account === undefined) ?
                            <><input type="text" value={"Not Connected"} className="p-bg" readonly='readonly'/></>
                            :
                            <>
                                <input type="text" value={account} className="p-bg" readonly='readonly'/>
                                </>
                            }
                            
            </div>
            
            </div>
            {msg.text === "" ? null : 
            <div className="header-msg"
                style={{
                    border: `1px solid rgba(${msg.color}, 1)`,
                    backgroundColor: `rgba(${msg.color}, 0.1)`,
                    maxWidth: "50%",
                    margin: "auto",
                    marginTop: "48px",
                    // visibility: msg.text ? 'visible' : 'hidden',
                    display: msg.text ? 'block' : 'none'
                }}
            >
                <p style={{
                    color: `rgba(${msg.color}, 1)`,
                    fontSize: '14px',
                    textAlign: "center",
                    padding: "0px",
                    margin: "0px",
                    marginTop: "0px",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    height: "100%"
                }}>
                    {msg.text}
                </p>
            </div>

            }
            
        </>
    )
}

export default Header;